@import "styles/common.scss";

.container {
  @include flex(center);
  column-gap: 6px;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: $zindex-toaster;
  padding: 9px 12px;
  border-radius: 99px;
  background: linear-gradient(0deg, var(--surface-overlay-max), var(--surface-overlay-max)), linear-gradient(0deg, var(--border-inverted-20), var(--border-inverted-20));
  box-shadow: 0px 1px 2px 0px #0914250A;
    border: 0.5px solid var(--border-inverted-20);
  color: var(--text-icon-text-icon-inverted-primary);

  .message {
    @include ui-text-ui-13-semibold;
  }
}
