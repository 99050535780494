@import 'styles/common.scss';

.plain-text {
    @include paragraph-p-15-regular;
    word-break: break-word;
    white-space: pre-line;

    a {
        color: var(--text-icon-interactive);
        &:hover {
            text-decoration: underline;
        }
    }

    &.dark-bg {
        a {
            color: var(--text-icon-primary);
        }
    }
    &.link-underlined {
        a {
            text-decoration: underline;
        }
    }
}
