@import 'styles/common.scss';
.searchSelectedFilter {
  color: var(--primary-color);
  border-radius: 6px;
}
.searchFilter {
  height: 22px;
  width: 22px;
}
.container {
  background: var(--bg-primary-webapp);
  min-height: calc(100vh - 80px);

  .search {
    height: 100%;
    @include sm-desktop-down {
      padding: 0 16px 96px;
    }
    @include sm-desktop-up {
      width: 680px;
      margin: 0 auto;
    }
    &Heading {
      padding-bottom: 0;
    }
    &Recent {
      padding-top: 24px;
    }
    &Title {
      @include f-inter-bold(2.4rem, 4rem, 1.8rem, 2.2rem);
    }
    &Filter {
      width: 22px;
      height: 22px;
    }
    .filterContainer {
      position: sticky;
      top: 0;
      z-index: $zindex-fixed;
      padding-top: 24px;
      padding-bottom: 16px;
      transition: background 200ms;

      @include medium {
        background: var(--bg-primary-webapp);
        transition: background 200ms;
      }

      @include sm-desktop-up {
        &.scrolled {
          background: var(--bg-primary);
          padding-left: calc(50vw - 340px);
          padding-right: calc(50vw - 340px);
          margin: 0 calc(-50vw + 350px);
        }
      }
    }

    .filter {
      @include sm-desktop-down {
        position: absolute;
        right: 0;
        top: 15px;
        &Hide {
          display: none;
        }
      }
    }
    .tabgroup {
      @include sm-desktop-up {
        margin-top: 12px;
        margin-bottom: -10px;
      }
      @include sm-desktop-down {
        margin-top: 10px;
      }
    }
  }
  .chevron {
    position: absolute;
    left: 0;
  }
  .scrolledSearchIcon {
    @include sm-desktop-down {
      position: absolute;
      top: 15px;
      right: 30px;
    }
  }
  .hamburger {
    @include sm-desktop-down {
      display: none;
    }
    position: absolute;
    right: 50px;
  }
  .input {
    flex: 1;
    &CommonSearch {
      @include sm-desktop-up {
        padding-top: 24px;
      }
    }
    input {
      border-radius: 20px;
      border: none;
      background: rgba(100, 101, 104, 0.1);
      padding: 11.5px 48px;
      width: 100%;
      @include f-inter-regular(1.7rem, 2.1rem);
      &:focus {
        border: none;
        caret-color: var(--primary-color);
      }
    }
    .startSearch {
      left: 0;
    }
  }
  .section {
    padding: 26px 0 32px;
    cursor: pointer;
    &Mobile {
      @include sm-desktop-down {
        padding-top: 26px;
      }
    }
    @include sm-desktop-up {
      @include flex;
      column-gap: 15px;
    }
    &Heading {
      padding-bottom: 10px;
    }
    img {
      @include sm-desktop-down {
        width: 100%;
        height: 142px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
  .title {
    @include f-inter-bold(1.6rem, 2.2rem);
  }
  .subsection {
    cursor: pointer;
    @include sm-desktop-down {
      & > div {
        margin-bottom: 16px;
        &:last-of-type {
          margin: 0;
        }
      }
    }
    @include sm-desktop-up {
      display: grid;
      column-gap: 16px;
      row-gap: 16px;
    }
  }
  .image {
    @include f-inter-bold(1.8rem, 2.2rem);
    color: var(--accent-text);
    padding: 0 16px;
    text-shadow: 0px 1px 0px var(--black-color);
    border-radius: 12px;
    @include sm-desktop-down {
      width: 100%;
      height: 142px;
    }
    @include sm-desktop-up {
      height: 88px;
      width: 100%;
    }
  }
  .nodata {
    @include flex-column(center, center);
    flex: 1;
    height: 70%;
    &Emoji {
      @include f-inter-regular(4rem, 6.4rem);
    }
    &Title {
      @include f-inter-regular(1.8rem, 2.8rem);
      color: var(--dark-grey);
      padding: 16px 0;
    }
  }
  .list {
    height: 100%;
    margin-top: 18px;
  }
  .card {
    margin-bottom: 12px;
    &:last-of-type {
      margin: 0;
    }
  }
  .bottomElement {
    height: 50px;
    width: 100%;
  }
  .spinner {
    @include flex-center;
    height: 70%;
    margin-top: 15px;
  }
  .recent {
    padding: 10px 12px;
    &Title {
      @include f-inter-bold(1.7rem, 2.2rem);
    }
    &Button {
      @include f-inter-regular(1.3rem, 1.6rem);
      color: var(--dark-grey);
      padding: 0;
    }
    &List {
      padding: 0 10px;
      background: var(--bg-primary);
      border-radius: 10px;
    }
    &Card {
      @include flex(flex-start, space-between);
      border-bottom: 1px dashed #ced0d4;
      &:last-of-type {
        border: none;
      }
    }
    &Close {
      color: var(--dark-grey);
      padding: 22px 16px;
    }
  }
  .logoLink {
    @include flex-center;
    height: 32px;
    @include sm-desktop-down {
      display: none;
    }
    position: absolute;
    left: 50px;
    top: 20px;
  }
  .hideSection {
    visibility: hidden;
  }
  .main {
    padding-top: 16px;
  }
}

.societyHeading {
  @include f-inter-extra-bold(1.6rem, 2.2rem);
  margin-bottom: 12px;
}
