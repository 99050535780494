@import 'styles/common.scss';

@mixin circle-icon($color) {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle r='8' transform='matrix(-1 0 0 1 8 7.99951)' fill='#{$color}'/%3E%3C/svg%3E%0A");
}

.Toggle {
  input {
    appearance: none;
    display: inline-block;
    width: 40px;
    height: 24px;
    border-radius: 90px;
    @include circle-icon('%23ffffff');
    background-repeat: no-repeat;
    background-position: 4px 4px;
    background-color: var(--surface-overlay-loud-plus);
    transition: background-position 0.25s ease-in-out;
    cursor: pointer;
    &:checked {
      background-position: 20px 4px;
      background-color: var(--primary-color);
    }
    &[disabled] {
      background-color: var(--surface-overlay-loud);
      cursor: not-allowed;

      & ~ label {
        color: var(--text-icon-disabled);
      }
    }
  }
  label {
    color: var(--text-primary);
  }
}
