@import 'styles/common.scss';

$position-distance: calc(100% + 4px);

.Tooltip {
  position: relative;
  display: inline-block;
  &Content {
    @include fns-semi-bold(1.3rem, 1.6rem);
    padding: 8px 12px;
    border-radius: 4px;
    position: absolute;
    z-index: $zindex-tooltip;
    &Rounded {
      box-shadow: 0px 1px 20px 0px rgba(20, 30, 46, 0.12);
      backdrop-filter: blur(6px);
      border-radius: 12px;
    }
    &Color {
      &Primary {
        background: var(--primary-color);
        color: var(--accent-text);
      }
      &Dark {
        background: rgba(9, 20, 37, 0.90);
        color: var(--accent-text);
      }
    }
    &Horizontal {
      &Left {
        left: 0;
      }
      &Center {
        left: 50%;
        transform: translateX(-50%);
      }
      &Right {
        right: 0;
      }
    }
    &Vertical {
      &Top {
        top: 0;
      }
      &Middle {
        top: 50%;
        transform: translateY(-50%);
      }
      &Bottom {
        bottom: 0;
      }
    }
    &Position {
      &Top {
        flex-direction: column;
        bottom: $position-distance;
      }
      &Bottom {
        flex-direction: column-reverse;
        top: $position-distance;
      }
      &Left {
        flex-direction: row;
        right: $position-distance;
      }
      &Right {
        flex-direction: row-reverse;
        left: $position-distance;
      }
    }
    &.v2Design {
        width: 320px;
        white-space: break-spaces;
        border-radius: 12px;
        box-shadow: 0px 1px 20px 0px rgba(20, 30, 46, 0.12);
        padding: 8px 12px;
        @include f-inter-regular(1.3rem, 1.8rem);
    }
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }
}
