@import 'styles/common.scss';

.modalContainer {
  @include mobile {
    z-index: 250;
  }
}
.ResponseModal {
  width: 604px;
  border-radius: 16px;
  max-height: calc(100vh - 160px);
  position: relative;

  @include mobile {
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
  }

  &Header {
    position: fixed;
    width: inherit;
    border-radius: 16px 16px 0 0;
    padding: 13px 16px;
    background: var(--bg-primary-webapp);
    z-index: 1;
    @include flex-center;

    @include small {
      border-radius: 20px 20px 0px 0px;
      background: var(--bg-primary);
    }

    .closeIcon {
      position: absolute;
      right: 16px;
    }

    p {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
    }
  }

  &Content {
    max-height: calc(100% - 144px);
    overflow: auto;
    padding: 60px 28px 32px;
    background: var(--bg-primary-webapp);

    @include small {
      padding: 64px 20px 8px;
      background: var(--bg-primary);
    }

    .completeProfileBanner {
        padding: 10px 8px 10px 12px;
        margin-bottom: 16px;
        border-radius: 12px;
        background: var(--surface-warning-silent);
        gap: 10px;
        @include flex(center, space-between);

        & > div {
            gap: 10px;
            @include flex-center;

            .rocketIcon {
                font-size: 20px;
            }

            .infoTitle {
                color: var(--text-icon-on-lights-secondary);
                @include f-inter-regular(1.4rem, 2rem);
            }
        }
    }

    .questionInputContainer {
      position: relative;

      .tabInfo {
        position: absolute;
        top: 15px;
        right: 16px;

        .tabKey {
          padding: 3px 5px;
          margin-right: 4px;
          border-radius: 4px;
          background: var(--ui-teal-light-surface-overlay-silent);
          color: var(--text-icon-on-lights-secondary);
          @include f-inter-regular-2(1.2rem, 1.7rem);
        }

        .infoText {
          color: var(--text-icon-on-lights-tertiary);
          @include f-inter-regular-2(1rem, 1.4rem);
        }
      }
    }

    .questionInput {
      margin-bottom: 16px;

      textarea {
        border-radius: 12px;
        border: 2px solid rgba(8, 12, 11, 0.3);
      }
    }

    .gptSuggestionBox {
      .labelContainer {
        margin-bottom: 4px;
        @include flex(center, space-between);

        p {
          @include f-inter-regular-2(1.4rem, 2rem);
        }

        .magicWandTooltip {
          border-radius: 12px;
          padding: 8px 10px;
        }

        .generateReply {
          color: #0c65e4;
          cursor: pointer;
          @include flex-center;

          .magicWand {
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }
        }
      }

      .inputWrapper {
        padding: 4px 4px 8px 4px;
        background: var(--surface-overlay-silent);
        border-radius: 12px;

        textarea {
          overflow: hidden;
          border: 1.5px solid var(--border-on-lights-15);
          box-shadow: 0px 1px 1px 0px rgba(8, 12, 11, 0.04);
        }

        .suggestionActions {
          padding: 2px 14px;
          margin-top: 4px;
          @include flex(center, space-between);

          @include small {
            @include flex-column(flex-start, center);
          }

          .infoWand {
            width: 16px;
            height: 16px;

            svg {
              width: 16px;
              height: 16px;

              path {
                fill: var(--text-primary-v2);
                fill-opacity: 0.62;
              }
            }
          }

          .successWand {
            svg {
              path {
                fill: var(--primary-color);
                fill-opacity: 1;
              }
            }
          }

          .errorWand {
            svg {
              path {
                fill: var(--text_icon-on_lights-critical);
                fill-opacity: 1;
              }
            }
          }

          .info {
            margin-left: 5px;
            color: var(--text-icon-on-lights-tertiary);
            @include f-inter-regular-2(1.3rem, 1.8rem);

            &.errorLabel {
              color: var(--text_icon-on_lights-critical);
            }
          }

          .infoWrapper {
            @include flex;

            @include small {
              margin-bottom: 6px;
            }
          }

          .actionCta {
            @include flex-center;

            button {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  &Action {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--border-on-lights-10);
    background: var(--bg-primary);
    padding: 28px;
    @include flex(center, flex-end);

    @include small {
      padding: 16px 20px;
    }

    button {
      &:not(:first-child) {
        margin-left: 8px;
      }

      @include small {
        width: 100%;
      }
    }
  }
}
