@import 'styles/common.scss';

.ChatPopUp {
  position: fixed;
  right: 30px;
  @include medium {
    bottom: 0;
  }
  @include small {
    bottom: 85px;
    z-index: 3;
  }
  @include sm-desktop-up {
    bottom: 20px;
    z-index: $zindex-fixed;
  }
  .logoContainer {
    width: 56px;
    height: 56px;
    background: var(--bg-primary);
    border: 0.5px solid #e6e7ea;
    box-shadow: 0px 2px 8px rgba(9, 20, 37, 0.05);
    border-radius: 32px;
    padding: 12px;
    cursor: pointer;
    @include sm-desktop-down {
      margin-right: 20px;
    }
    @include sm-desktop-up {
      margin-right: 50px;
    }
  }
  .logo {
    width: 32px;
    height: 32px;
  }
  .chatBox {
    position: sticky;
    background: var(--bg-primary);
    border: 0.5px solid #e6e7ea;
    box-shadow: 0px 2px 8px rgba(9, 20, 37, 0.05);
    border-radius: 16px;
    @include medium {
      width: 360px;
      margin-right: 25px;
    }
    @include small {
      margin: 8px;
      width: 100%;
    }
    @include sm-desktop-up {
      width: 360px;
      margin-right: 50px;
    }
    .header {
      border-bottom: 1px solid #eff0f0;
      padding: 12px 16px;
      .row {
        column-gap: 10px;
      }
      .title {
        @include f-inter-bold(1.7rem, 2rem);
      }
    }
    &Content {
      padding: 24px;
    }
    .text {
      @include f-inter-regular(1.7rem, 2.2rem);
      letter-spacing: -0.32px;
      background: rgba(100, 101, 104, 0.1);
      border-radius: 16px;
      padding: 8px 12px;
      width: fit-content;
      margin-bottom: 8px;
      &:last-of-type {
        margin: 0;
      }
      & > span {
        font-weight: 700;
      }
    }
    .button {
      margin-top: 24px;
    }
  }
}
