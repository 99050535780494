@import 'styles/common.scss';

.Link {
  &Variant {
    &Inline {
      @include fns-semi-bold(1.4rem, 2.4rem);
      color: var(--text-primary);
    }
  }
  &Color {
    &Primary {
      color: var(--primary-color);
    }
  }
  &Underlined {
    text-decoration: underline;
  }
  &UnderlinedOnHover {
    &:hover {
      text-decoration: underline;
    }
  }
}
