// :root {
//   --ON: initial;
//   --OFF: ;
// }

// .theme-default,
// .theme-light {
//   --light: var(--ON);
//   --dark: var(--OFF);
// }

// .theme-dark {
//   --light: var(--OFF);
//   --dark: var(--ON);
// }

/** uncomment when we start supporting dark theme */
// @media (prefers-color-scheme: dark) {
//   .theme-default {
//     --light: var(--OFF);
//     --dark: var(--ON);
//   }
// }

// @function light-dark($light-color, $dark-color) {
//   @return var(--light, #{$light-color}) var(--dark, #{$dark-color});
// }

@mixin root-prop($prop: null, $value: null) {
  @if ($prop and $value) {
    #{$prop}: $value;
  }
}

$disabled-color: #a8a8a8;

$primary-color-dark: #008d8a;
$primary-color-base: #009491;
$primary-color-light: rgba(
  $color: $primary-color-base,
  $alpha: 0.6,
);
$primary-color-faint: rgba(
  $color: $primary-color-base,
  $alpha: 0.2,
);
$primary-color-disabled: $disabled-color;

$accent-text-color-base: #ffffff;
$accent-text-color-light: rgba(
  $color: $accent-text-color-base,
  $alpha: 0.8,
);
$accent-text-color-faint: rgba(
  $color: $accent-text-color-base,
  $alpha: 0.6,
);
$accent-text-color-disabled: $disabled-color;

$text-primary-light: #222222;
$text-primary-light-v2: #080c0b;
$text-primary-light-v3: #080c0bbd;
$text-primary-dark: #ffffff;

$text-secondary-light: #4a4a4a;
$text-secondary-dark: rgba(
  $color: $text-primary-dark,
  $alpha: 0.8,
);

$text-tertiary-light: #8d8d8d;
$text-tertiary-dark: rgba(
  $color: $text-primary-dark,
  $alpha: 0.6,
);

$text-disabled-light: #c6c6c6;
$text-disabled-dark: $disabled-color;

$bg-primary-light: #ffffff;
$bg-primary-dark: #000000;

$border-color-light: rgba(
  $color: #000000,
  $alpha: 0.1,
);
$border-color-dark: rgba(
  $color: #ffffff,
  $alpha: 0.1,
);

$bg-secondary-light: #faf7f7;
$bg-secondary-dark: #262626;
$bg-tertiary: #f2f2f2;

$bg-tertiary: #f8f8f8;
$bg-tertiary-light: #f4efef;

$complementary-primary-base: #fdc231;
$complementary-primary-light: #fcf4d6;

$complementary-secondary-base: #b51e3a;
$complementary-secondary-light: #fed1d9;

$complementary-tertiary-base: #083f6e;
$complementary-tertiary-light: #edf5ff;

$border-color: #bdbdbd;
$border-color-secondary: #f2ecec;
$border-color-tertiary: #f8f8f8;
$border-border-10: #080c0b1a;

$scroll-bar-color: #c4c4c4;

$link-color: #0066ff;

$event-type-border-color: #ffd3cd;
$event-type-background-color: #fff4f1;
$job-type-border-color: #c2e7f4;
$job-type-background-color: #f2fbff;

$dot-color: #8d92a3;

$bg-primary-webapp: #f6f6fa;

$chip-border-color: #ebebf1;
$chip-bg-color: #f9f9f9;
$chip-bg-silent: #efecec;

$table-th-border: rgba(74, 74, 74, 0.1);
$table-th-border-dark: #ababab;
$expired-text-light: rgba(32, 34, 36, 0.5);
$hired-text-dark: rgba(1, 67, 66, 1);
$selection-text-light: rgba(255, 255, 255, 0.2);
$selection-text-light-6: rgba(255, 255, 255, 0.6);
$selection-text-dark: rgba(9, 20, 37, 0.4);
$danger-color-light: #ec3838;
$danger-text-light: #ed001f;
$selection-text-v2: #080c0b4d;
$selection-text-v3: #080c0b0a;
$selection-text-v4: #080c0b26;
$selection-text-v5: #080c0b08;
$deadline-bg-color: #ffeedf;
$deadline-text-color: #ec7938;
$deadline-border-color: #ffd5ae;
$table-box-color: rgba(20, 30, 46, 0.12);

//new colors
$ui-teal-light-surface-tertiary: #f4f6f8;
$ui-teal-light-surface-tertiary-hover: #ecedee;
$ui-teal-light-surface-tertiary-pressed: #e0e1e2;
$ui-teal-light-bg-primary: #ffffff;
$ui-teal-light-bg-secondary: #eff1f3;
$ui-teal-light-surface-brand-hover: #008d8a;
$ui_teal_light-surface-surface-brand-pressed: #018684;
$ui_teal_light-surface-primary-hover: #f3f3f3;
$ui_teal_light-surface-primary-pressed: #e6e7e7;
$ui_teal_light-surface-warning-silent: #fdf6db;
$ui-teal-light-surface-brand-silent: #ebffff;
$ui-teal-light-surface-warning: #ffcc00;
$ui-teal-light-surface-overlay-silent: rgba(8, 12, 11, 0.05);

$surface-overlay-silent: rgba(8, 12, 11, 0.05);
$surface-overlay-max: rgba(8, 12, 11, 0.8);
$surface-brand-silent: #ebffff;
$surface-critical: #E5132E;
$surface-critical-silent: #feecee;
$surface-secondary-hover: #e3e6e7;
$surface-overlay-minimal: rgba(8, 12, 11, 0.03);
$surface-pressed-surface-primary-pressed: #e6e7e7;

$text-icon-on-lights-tertiary: rgba(8, 12, 11, 0.62);
$text_icon-on_lights-critical: #e5132e;
$text-icon-on-lights-secondary: rgba(8, 12, 11, 0.74);
$text-icon-on-lights-warning: #bf8c01;
$text-icon-disabled: rgba(8, 12, 11, 0.3);
$text-icon-interactive: #0c65e4;
$text-icon-placeholder: rgba(8, 12, 11, 0.44);
$text_icon-on_lights-tertiary-pressed: rgba(8, 12, 11, 0.66);
$text_icon-inverted-secondary: rgba(255, 255, 255, 0.88);

$surface-warning: #fc0;
$surface-warning-silent: #fdf6db;
$surface-interactive-silent: #edf7ff;

$border-on-lights-1: rgba(8, 12, 11, 0.1);
$border-on-lights-3: rgba(8, 12, 11, 0.3);
$border-on-lights-8: rgba(8, 12, 11, 0.8);
$border-on-lights-06: rgba(8, 12, 11, 0.06);
$border-on-lights-10: rgba(8, 12, 11, 0.1);
$border-on-lights-15: rgba(8, 12, 11, 0.15);
$border-on-lights-20: rgba(8, 12, 11, 0.2);
$border-on-lights-62: rgba(8, 12, 11, 0.62);
$border-on-lights-03: rgba(8, 12, 11, 0.03);
$border-on-lights-09: rgba(8, 12, 11, 0.09);
$border-on-lights-2: rgba(8, 12, 11, 0.2);
$border-inverted-20: #ffffff33;

$black74: rgba(9, 20, 37, 0.74);
$greys1: #777e90;
$button-danger: #ec3838;

$box-shadow-dark-08: rgba(0, 0, 0, 0.8);


:root {
  // brand colors
  @include root-prop(--primary-color-dark, $primary-color-dark);
  @include root-prop(--primary-color, $primary-color-base);
  @include root-prop(--primary-color-light, $primary-color-light);
  @include root-prop(--primary-color-faint, $primary-color-faint);
  @include root-prop(--primary-color-disabled, $primary-color-disabled);

  // accent text colors
  @include root-prop(--accent-text, $accent-text-color-base);
  @include root-prop(--accent-text-light, $accent-text-color-light);
  @include root-prop(--accent-text-faint, $accent-text-color-faint);
  @include root-prop(--accent-text-disabled, $accent-text-color-disabled);

  // text colors
  @include root-prop(--text-primary-light, $text-primary-light);
  @include root-prop(--text-primary, $text-primary-light);
  @include root-prop(--text-primary-v2, $text-primary-light-v2);
  @include root-prop(--text-primary-v3, $text-primary-light-v3);
  @include root-prop(--text-secondary, $text-secondary-light);
  @include root-prop(--text-tertiary, $text-tertiary-light);
  @include root-prop(--text-disabled, $text-disabled-light);

  // background colors
  @include root-prop(--bg-primary-light, $bg-primary-light);
  @include root-prop(--bg-primary-dark, $bg-primary-dark);

  @include root-prop(--bg-primary, $bg-primary-light);
  @include root-prop(--bg-secondary, $bg-secondary-light);
  @include root-prop(--bg-tertiary, $bg-tertiary);

  @include root-prop(--bg-tertiary-light, $bg-tertiary-light);
  @include root-prop(--bg-primary-webapp, $bg-primary-webapp);

  // complementary colors
  @include root-prop(--complementary-primary, $complementary-primary-base);
  @include root-prop(
    --complementary-primary-light,
    $complementary-primary-light
  );
  @include root-prop(--complementary-secondary, $complementary-secondary-base);
  @include root-prop(
    --complementary-secondary-light,
    $complementary-secondary-light
  );
  @include root-prop(--complementary-tertiary, $complementary-tertiary-base);
  @include root-prop(
    --complementary-tertiary-light,
    $complementary-tertiary-light
  );

  // border color
  @include root-prop(--border-color, $border-color);
  @include root-prop(--border-color-secondary, $border-color-secondary);
  @include root-prop(--border-color-tertiary, $border-color-tertiary);
  @include root-prop(--border-border-10, $border-border-10);
  @include root-prop(--border-border-15, rgba(8, 12, 11, 0.15));

  // scroll color
  @include root-prop(--scroll-bar-color, $scroll-bar-color);

  // shadow color
  @include root-prop(--shadow-color, $border-color-light);
  // link color
  @include root-prop(--link-color, $link-color);

  //opportunity type border and background color
  @include root-prop(--event-type-border-color, $event-type-border-color);
  @include root-prop(
    --event-type-background-color,
    $event-type-background-color
  );
  @include root-prop(--job-type-border-color, $job-type-border-color);
  @include root-prop(--job-type-background-color, $job-type-background-color);

  @include root-prop(--black-color, #091425);
  @include root-prop(--dark-grey, #646568);
  @include root-prop(--dot-color, $dot-color);
  @include root-prop(--chip-border-color, $chip-border-color);
  @include root-prop(--chip-bg-color, $chip-bg-color);
  @include root-prop(--chip-bg-silent, $chip-bg-silent);
  @include root-prop(--table-th-border, $table-th-border);
  @include root-prop(--table-th-border-dark, $table-th-border-dark);
  @include root-prop(--expired-text-light, $expired-text-light);
  @include root-prop(--hired-text-dark, $hired-text-dark);
  @include root-prop(--selection-text-light, $selection-text-light);
  @include root-prop(--selection-text-light-6, $selection-text-light-6);
  @include root-prop(--selection-text-dark, $selection-text-dark);
  @include root-prop(--danger-color-light, $danger-color-light);
  @include root-prop(--danger-text-light, $danger-text-light);
  @include root-prop(--selection-text-v2, $selection-text-v2);
  @include root-prop(--selection-text-v3, $selection-text-v3);
  @include root-prop(--selection-text-v4, $selection-text-v4);
  @include root-prop(--selection-text-v5, $selection-text-v5);
  @include root-prop(--deadline-bg-color, $deadline-bg-color);
  @include root-prop(--deadline-text-color, $deadline-text-color);
  @include root-prop(--deadline-border-color, $deadline-border-color);
  @include root-prop(--table-box-color, $table-box-color);
  @include root-prop(--black-color-1, rgba(9, 20, 37, 0.1));
  @include root-prop(--black-color-9, rgba(9, 20, 37, 0.9));
  @include root-prop(--black-color-03, rgba(9, 20, 37, 0.03));
  @include root-prop(--black-color-04, rgba(9, 20, 37, 0.04));

  // new colors
  @include root-prop(
    --ui-teal-light-surface-tertiary,
    $ui-teal-light-surface-tertiary
  );
  @include root-prop(
    --ui-teal-light-surface-tertiary-hover,
    $ui-teal-light-surface-tertiary-hover
  );
  @include root-prop(
    --ui-teal-light-surface-tertiary-pressed,
    $ui-teal-light-surface-tertiary-pressed
  );
  @include root-prop(--ui-teal-light-bg-primary, $ui-teal-light-bg-primary);
  @include root-prop(--ui-teal-light-bg-secondary, $ui-teal-light-bg-secondary);
  @include root-prop(
    --ui-teal-light-surface-brand-hover,
    $ui-teal-light-surface-brand-hover
  );
  @include root-prop(
    --ui_teal_light-surface-surface-brand-pressed,
    $ui_teal_light-surface-surface-brand-pressed
  );
  @include root-prop(
    --ui_teal_light-surface-primary-hover,
    $ui_teal_light-surface-primary-hover
  );
  @include root-prop(
    --ui_teal_light-surface-primary-pressed,
    $ui_teal_light-surface-primary-pressed
  );

  @include root-prop(--surface-overlay-silent, $surface-overlay-silent);
  @include root-prop(--surface-overlay-max, $surface-overlay-max);
  @include root-prop(--surface-overlay-loud, #080c0b1a);
  @include root-prop(--surface-overlay-loud-plus, #080c0b26);
  @include root-prop(--surface-inverted, #080c0b);
  @include root-prop(--surface-primary, #212222);
  @include root-prop(--surface-primary-hover, #0f1312);
  @include root-prop(--surface-brand-silent, $surface-brand-silent);
  @include root-prop(--surface-critical-silent, $surface-critical-silent);
  @include root-prop(--surface-critical, $surface-critical);
  @include root-prop(--surface-secondary-hover, $surface-secondary-hover);
  @include root-prop(--surface-overlay-minimal, $surface-overlay-minimal);
  @include root-prop(
    --surface-pressed-surface-primary-pressed,
    $surface-pressed-surface-primary-pressed
  );
  @include root-prop(
    --text-icon-on-lights-secondary,
    $text-icon-on-lights-secondary
  );
  @include root-prop(--surface-warning, $surface-warning);
  @include root-prop(--surface-warning-silent, $surface-warning-silent);
  @include root-prop(--surface-interactive-silent, $surface-interactive-silent);
  @include root-prop(--text_icon-primary, #080c0b);
  @include root-prop(--text_icon-secondary, #080c0bbd);
  @include root-prop(--text_icon-placeholder, #080c0b70);
  @include root-prop(
    --text_icon-on_lights-critical,
    $text_icon-on_lights-critical
  );
  @include root-prop(
    --text-icon-on-lights-warning,
    $text-icon-on-lights-warning
  );
  @include root-prop(--text-icon-disabled, $text-icon-disabled);
  @include root-prop(--text-icon-tertiary, #080c0b9e);
  @include root-prop(--text-icon-interactive, $text-icon-interactive);
  @include root-prop(--text-icon-placeholder, $text-icon-placeholder);
  @include root-prop(--text_icon-on_lights-tertiary-pressed, $text_icon-on_lights-tertiary-pressed);
  @include root-prop(--text_icon-inverted-secondary, $text_icon-inverted-secondary);
  @include root-prop(--text-icon-inverted-secondary, $text-icon-inverted-secondary);
  @include root-prop(--text-icon-on-lights-tertiary, $text-icon-on-lights-tertiary);
  @include root-prop(--text-icon-text-icon-inverted-primary, #fff);
  @include root-prop(
    --text_icon-inverted-secondary,
    $text_icon-inverted-secondary
  );
  @include root-prop(--border-on-lights-1, $border-on-lights-1);
  @include root-prop(--border-on-lights-3, $border-on-lights-3);
  @include root-prop(--border-on-lights-03, $border-on-lights-03);
  @include root-prop(--border-on-lights-2, $border-on-lights-2);
  @include root-prop(--border-on-lights-09, $border-on-lights-09);
  @include root-prop(--border-on-lights-8, $border-on-lights-8);
  @include root-prop(--border-on-lights-06, $border-on-lights-06);
  @include root-prop(--border-on-lights-10, $border-on-lights-10);
  @include root-prop(--border-on-lights-15, $border-on-lights-15);
  @include root-prop(--border-on-lights-20, $border-on-lights-20);
  @include root-prop(--border-on-lights-62, $border-on-lights-62);
  @include root-prop(--border-inverted-20, $border-inverted-20);
  @include root-prop(--black74, $black74);
  @include root-prop(
    --ui_teal_light-surface-warning-silent,
    $ui_teal_light-surface-warning-silent
  );
  @include root-prop(
    --ui-teal-light-surface-brand-silent,
    $ui-teal-light-surface-brand-silent
  );
  @include root-prop(
    --ui-teal-light-surface-warning,
    $ui-teal-light-surface-warning
  );
  @include root-prop(
    --ui-teal-light-surface-overlay-silent,
    $ui-teal-light-surface-overlay-silent
  );
  @include root-prop(--greys1, $greys1);
  @include root-prop(--button-danger, $button-danger);
  @include root-prop(--box-shadow-dark-08, $box-shadow-dark-08);
}
