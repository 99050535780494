@import 'styles/common.scss';

.Deadline {
  border-radius: 16px;
  @include f-inter-semi-bold(1.3rem, 1.5rem);
  padding: 4px 6px;
}
.silver {
  background: #f9f9f9;
  border: 0.5px solid #ebebf1;
  color: #646568;
}
.yellow {
  background: #fff8df;
  border: 0.5px solid #fde66e;
  color: #c3a815;
}
.orange {
  background: #ffeedf;
  border: 0.5px solid #ffd5ae;
  color: #ec7938;
}
.red {
  background: #ffe9e4;
  border: 0.5px solid #ffcbbf;
  color: #f46d42;
}
