// based on stat counter - https://gs.statcounter.com/screen-resolution-stats/all/united-kingdom

$mobile: 414px !default;
$tablet: 767px !default;
$small-desktop: 1080px !default;
$desktop: 1280px !default;
$large-desktop: 1440px !default;
$very-large-desktop: 1800px !default;

$breakpoints: (
  'x-small': (
    max-width: #{$mobile},
  ),
  'xs-up': (
    min-width: #{$mobile + 1px},
  ),
  'small': (
    max-width: #{$tablet},
  ),
  'medium': (
    max-width: #{$desktop - 1px},
  ),
  'sm-desktop-down': (
    max-width: #{$small-desktop},
  ),
  'sm-desktop-up': (
    min-width: #{$small-desktop},
  ),
  'large': (
    min-width: #{$desktop},
  ),
  'x-large': (
    min-width: #{$large-desktop},
  ),
  'xx-large': (
    min-width: #{$very-large-desktop},
  ),
  'mobile': (
    max-width: #{$tablet},
  ),
  'desktop': (
    min-width: #{$tablet},
  ),
) !default;

// New breakpoints
$new-breakpoints: (
  b1440: 1440px,
  b1300: 1300px,
  b1299: 1299px,
  b1200: 1200px,
  b1150: 1150px,
  b1149: 1149px,
  b1050: 1050px,
  b1049: 1049px,
  b1000: 1000px,
  b999: 999px,
  b946: 946px,
  b800: 800px,
  b769: 769px,
  b768: 768px,
  b650: 650px,
  b610: 610px,
  b470: 470px,
);

@mixin media-breakpoint-down($breakpoint) {
  @media screen and (max-width: map-get($new-breakpoints, $breakpoint) - 1) {
    @content;
  }
}

@mixin media-breakpoint-between($breakpoint1, $breakpoint2) {
  @media screen and (min-width: map-get($new-breakpoints, $breakpoint1)) and (max-width: map-get($new-breakpoints, $breakpoint2)) {
    @content;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @media screen and (min-width: map-get($new-breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin media-breakpoint-small() {
  @include media-breakpoint-down(b800) {
    @content;
  }
}

@mixin media-breakpoint-medium-only() {
  @include media-breakpoint-between(b800, b1200) {
    @content;
  }
}

@mixin media-breakpoint-large() {
  @include media-breakpoint-up(b1200) {
    @content;
  }
}

@mixin media-breakpoint-xlarge() {
  @include media-breakpoint-up(b1440) {
    @content;
  }
}
