@import 'styles/common.scss';

.ButtonV3 {
  position: relative;
  background: none;
  border: none;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  @include flex-center;

  &Size {
    &Small {
      border-radius: 8px;
      padding: 6px 11px;
      @include f-inter-semi-bold(1.3rem, 1.8rem);

      .ButtonV3Icon,
      .ButtonV3Spinner .ButtonV3Loader {
        width: 14px;
        height: 14px;
      }

      .ButtonV3IconLeft {
        margin-right: 6px;
      }

      .ButtonV3IconRight {
        margin-left: 6px;
      }
    }

    &Medium {
      border-radius: 10px;
      padding: 9px 15px;
      @include f-inter-semi-bold(1.4rem, 2rem);

      .ButtonV3Icon,
      .ButtonV3Spinner .ButtonV3Loader {
        width: 16px;
        height: 16px;
      }

      .ButtonV3IconLeft {
        margin-right: 8px;
      }

      .ButtonV3IconRight {
        margin-left: 8px;
      }
    }

    &Large {
      border-radius: 12px;
      padding: 12px 19px;
      @include f-inter-semi-bold(1.6rem, 2.2rem);

      .ButtonV3Icon,
      .ButtonV3Spinner .ButtonV3Loader {
        width: 18px;
        height: 18px;
      }

      .ButtonV3IconLeft {
        margin-right: 10px;
      }

      .ButtonV3IconRight {
        margin-left: 10px;
      }
    }

    &Xlarge {
      border-radius: 14px;
      padding: 15px 23px;
      @include f-inter-semi-bold(1.8rem, 2.5rem);

      .ButtonV3Icon,
      .ButtonV3Spinner .ButtonV3Loader {
        width: 20px;
        height: 20px;
      }

      .ButtonV3IconLeft {
        margin-right: 12px;
      }

      .ButtonV3IconRight {
        margin-left: 12px;
      }
    }

    &Xxlarge {
      border-radius: 16px;
      padding: 17px 27px;
      @include f-inter-semi-bold(2rem, 2.8rem);

      .ButtonV3Icon,
      .ButtonV3Spinner .ButtonV3Loader {
        width: 22px;
        height: 22px;
      }

      .ButtonV3IconLeft {
        margin-right: 15px;
      }

      .ButtonV3IconRight {
        margin-left: 15px;
      }
    }
  }

  &VariantText {
    background: transparent;
    color: var(--text-primary-v2);
    border: 1px solid transparent;
    box-shadow: none;

    &:hover {
      background: rgba(8, 12, 11, 0.05);
    }

    &:active {
      background: rgba(8, 12, 11, 0.1);
    }
  }

  &ColorBlack {
    color: var(--text-primary);
  }

  &ColorPrimary {
    &.ButtonV3VariantContained {
      background: var(--primary-color);
      color: var(--accent-text);
      border: 1px solid var(--primary-color);

      box-shadow: 0px 1px 2px rgba(1, 67, 66, 0.06);

      &:hover {
        box-shadow: 0px 2px 3px rgba(1, 67, 66, 0.07);
        @include desktop{
          background: var(--ui-teal-light-surface-brand-hover);
        }
      }

      &:active {
        background: var(--ui_teal_light-surface-surface-brand-pressed);
      }
    }
    &.ButtonV3VariantText {
      color: var(--primary-color);
    }
  }

  &ColorSecondary {
    &.ButtonV3VariantContained {
      background: var(--ui-teal-light-bg-primary);
      color: var(--text-primary-v2);
      border: 1px solid rgba(8, 12, 11, 0.15);
      box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);

      &:hover {
        box-shadow: 0px 2px 3px rgba(8, 12, 11, 0.06);
        background: var(--ui_teal_light-surface-primary-hover);
      }

      &:active {
        background: var(--ui_teal_light-surface-primary-pressed);
      }
    }

    &.ButtonV3Borderless {
      border: none;
      box-shadow: none;
    }
  }

  &ColorDanger {
    &.ButtonV3VariantContained {
      background: var(--button-danger);
      color: var(--accent-text);
      border: 0;
      box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);

      &:hover {
        box-shadow: 0px 2px 3px rgba(8, 12, 11, 0.06);
        background: var(--text_icon-on_lights-critical);
      }

      &:active {
        background: var(--complementary-secondary);
      }
    }

    &.ButtonV3Borderless {
      border: none;
    }
  }

  &ColorBlue {
    color: var(--link-color);
  }
  &ColorSilent {
    background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.05));
    &:hover {
      background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.08));
    }
  }
  &ColorPrimaryPressed{
    box-shadow: 0px 2px 3px rgba(8, 12, 11, 0.06);
    background: var(--ui_teal_light-surface-primary-hover);
    border: 1px solid rgba(8, 12, 11, 0.15);
  }
  &[disabled] {
    pointer-events: none;
    cursor: default;
    background: rgba(239, 241, 243, 0.97);
    color: rgba(8, 12, 11, 0.3);
    box-shadow: none;
    border: 1px solid rgba(239, 241, 243, 0.97);
  }

  &FullWidth {
    width: 100%;
  }

  &Loading {
    cursor: default;
    pointer-events: none;

    .ButtonV3Content,
    .ButtonV3Icon {
      visibility: hidden;
    }

    &.ButtonV3ColorPrimary {
      &.ButtonV3VariantContained {
        background: var(--ui_teal_light-surface-surface-brand-pressed);
      }
    }

    &.ButtonV3ColorSecondary {
      &.ButtonV3VariantContained {
        background: var(--ui_teal_light-surface-primary-pressed);
      }
    }

    &.ButtonV3VariantText {
      background: rgba(8, 12, 11, 0.1);
    }
  }

  &Spinner {
    position: absolute;
  }
}

.label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
