@import 'styles/common.scss';

.EventCard {
  border-radius: 16px;
  padding: 28px;
  background: var(--ui-teal-light-surface-tertiary);
  &:hover {
    background: var(--ui-teal-light-surface-tertiary-hover);
  }
  @include small {
    padding: 20px;
    border-radius: 12px;
  }
  .details {
    @include medium {
      height: 64px;
    }
    @include small {
      height: 42px;
    }

    .typeTitle {
      @include f-inter-bold(1.4rem, 2rem);
      color: #ec3843;
    }
    .title {
      @include multi-line-ellipsis(2);
      word-break: break-word;
      white-space: normal;
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      padding-bottom: 4px;
    }
    .date {
      @include f-inter-semi-bold(1.3rem, 1.8rem);
      color: var(--text_icon-on_lights-critical);
      @include small {
      }
    }
  }

  .img {
    border: 0.5px solid rgba(8, 12, 11, 0.1);
    border-radius: 8px;
    @include medium {
      width: 96px;
      height: 64px;
    }
    @include small {
      width: 64px;
      height: 42px;
    }
    @include large {
      width: 96px;
      height: 64px;
    }
  }
  .registrations {
    &Row {
      padding-top: 16px;
      column-gap: 16px;
    }
    &Col {
      @include flex;
      .profile {
        & > div {
          border-color: var(--ui-teal-light-surface-tertiary);
        }
      }
      .number {
        @include f-inter-regular(1.3rem, 1.8rem);
        @include flex-center;
        background: var(--bg-primary);
        min-width: 27px;
        border: 0.5px solid rgba(109, 131, 163, 0.1);
        text-align: center;
        height: 100%;
        border-radius: 100px;
        padding: 0 6px 0 4px;

        &Border {
          height: 29px;
          border-radius: 100px;
          border: 1px solid var(--ui-teal-light-surface-tertiary);
        }
      }
    }
    &Desc {
      @include f-inter-regular(1.3rem, 1.8rem);
      @include small {
        padding-top: 4px;
      }
    }
  }
  .cohost {
    @include f-inter-regular-2(1.3rem, 1.8rem);
    color: var(--text-icon-on-lights-secondary);
  }
  .chip {
    @include flex-center;
    color: var(--dark-grey);
    border: 0.5px solid var(--border-on-lights-15);
    border-radius: 16px;
    background: var(--surface-overlay-minimal);
    padding: 4px 8px;
    @include f-inter-regular(1.5rem, 1.8rem);
    &Row {
      margin-top: 8px;
      column-gap: 4px;
      row-gap: 4px;
    }
  }
}
