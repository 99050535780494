@import 'styles/common.scss';

.IconButton {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 3px;
  &:disabled {
    cursor: default;
  }
  &:hover {
    background: rgba(8, 12, 11, 0.05);
    border-radius: 8px;
  }

  .label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
}
