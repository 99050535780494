@import 'styles/common.scss';

.SignIn {
  position: relative;
  @include flex-column(center, center);
  @include mobile {
    width: 100%;
  }
  @include desktop {
    background: var(--ui-teal-light-bg-primary);
    padding: 56px 48px;
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px rgba(8, 12, 11, 0.09);
    width: 530px;
    max-width: 530px;
  }
  .chevron {
    position: absolute;
    @include mobile {
      top: 0;
      left: 0;
    }
    @include desktop {
      top: 18px;
      left: 18px;
    }
  }
  .title {
    text-align: center;
    @include f-inter-bold(2.8rem, 3.8rem, 2rem, 2.8rem);
    &Modal {
      @include f-inter-bold(2.4rem, 3.2rem);
      @include mobile {
        margin: 0px 8px;
        @include f-inter-bold(2rem, 2.8rem);
        letter-spacing: -0.32px;
      }
    }
    &Mobile {
      @include mobile {
        @include f-inter-regular-2(1.4rem, 2rem);
        padding-top: 12px;
      }
    }
  }
  .desc {
    @include f-inter-regular(1.4rem, 2.2rem, 1.3rem, 1.8rem);
    margin-top: 8px;
    @include mobile {
      text-align: center;
      margin: 8px 8px 0px 8px;
    }
  }
  .actionContainer {
    width: 100%;
  }
  .gap {
    @include mobile {
      padding-top: 200px;
    }
  }
  .error {
    color: rgba(8, 12, 11, 0.74);
    padding: 10px 8px 10px 12px;
    margin-top: 8px;
    border-radius: 12px;
    background: var(--ui_teal_light-surface-warning-silent);
    column-gap: 10px;
    @include flex(center, space-between);

    a {
      color: var(--text-icon-interactive);
    }
    .cancelError {
      height: 32px;
      width: 32px;
      padding: 7px;
      color: rgba(8, 12, 11, 0.74);
    }
  }
  .button {
    &Google,
    &Apple,
    &Continue {
      margin-top: 16px;
      @include f-inter-semi-bold(1.8rem, 2.5rem);
      padding: 14.5px 24px;
      border-radius: 14px;
    }
    &Google {
      margin-top: 48px;
      @include mobile{
        margin-top: 40px;
      }
    }
  }
  .startIcon {
    height: 22px;
    width: 22px;
    margin-right: 12px;
  }
  .separator {
    border-top: 1px solid var(--border-on-lights-15);
    width: 31%;
    margin: 0 8px;

    &Row {
      color: var(--text-icon-on-lights-tertiary);
      margin: 20px 0;
      padding: 14px 0px;
      @include mobile {
        margin: 16px 0;
        padding: 0px;
      }
    }

    &Text {
      @include f-inter-regular(1.4rem, 2rem);
      @include mobile {
        @include f-inter-regular(1.2rem, 1.7rem);
      }
    }
  }
  .signInput {
    @include f-inter-regular(1.8rem, 2.5rem);
    padding: 14px 16px;
    border: 1.5px solid #080c0b26;
    border-radius: 12px;
    &:focus {
      border: 2px solid #080c0b4d;
    }
  }

  .terms {
    &Row {
      padding-top: 40px;
    }
  }
  .termsRow,
  .termsText {
    text-align: center;
    @include f-inter-regular(1.3rem, 1.8rem);
    color: var(--text-icon-on-lights-tertiary);
  }
}
.otpContainer {
  @include desktop {
    width: 460px;
  }
}
.hideMobile {
  @include mobile {
    display: none;
  }
}
.hideDesktop {
  @include desktop {
    display: none;
  }
}
