@import 'styles/common.scss';

.Icon {
  @include flex(center);
  &Size {
    &Xxsmall {
      height: 8px;
      width: 8px;
      min-width: 8px;
    }
    &Xxsmallplus {
      height: 10px;
      width: 10px;
      min-width: 10px;
    }
    &Xsmall {
      height: 12px;
      width: 12px;
      min-width: 12px;
    }
    &Xsmallplus {
      height: 14px;
      width: 14px;
      min-width: 14px;
    }
    &Small {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
    &Medium {
      height: 18px;
      width: 18px;
      min-width: 18px;
    }
    &Mediumplus {
      height: 20px;
      width: 20px;
      min-width: 20px;
    }
    &Xmediumplus {
      height: 22px;
      width: 22px;
      min-width: 22px;
    }
    &Large {
      height: 24px;
      width: 24px;
      min-width: 24px;
    }
    &Xlarge {
      height: 32px;
      width: 32px;
      min-width: 32px;
    }
    &Xxlarge {
      height: 40px;
      width: 40px;
      min-width: 40px;
    }
    &Xxxlarge {
      height: 48px;
      width: 48px;
      min-width: 48px;
    }
    &Auto {
      height: auto;
      width: auto;
    }
  }
}
