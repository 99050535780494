@import 'styles/common.scss';

$danger-color: #ec3838;

.container {
  position: relative;

  .button {
    border: none;
    border-radius: 7px;
    background: transparent;
    cursor: pointer;
    padding: 5px;
    &[aria-expanded='true'] {
      background: rgba(100, 101, 104, 0.05);
    }

    &Icon {
      @include flex-center;
      height: 18px;
      width: 18px;
      color: rgba(8, 12, 11, 1);
    }

    &Container {
      position: relative;
      background: transparent;
      border: none;
      padding: 0;

      &:focus {
        outline: none;
      }
    }
  }

  ul {
    min-width: 220px;
    width: auto;
    position: absolute;
    background: var(--bg-primary-light);
    box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
    border-radius: 12px;
    outline: none;

    &:focus {
      outline: 0;
    }

    .item {
      @include flex(center, space-between);
      @include f-inter(1.5rem, 2.2rem);
      column-gap: 16px;
      width: auto;
      min-width: 100%;
      height: 44px;
      padding: 11px 16px;
      color: var(--black-color);
      white-space: nowrap;
      background: none;
      border: none;
      cursor: pointer;
      &.danger {
        color: $danger-color;
      }
      &.disabled {
        color: var(--selection-text-v2);
        cursor: not-allowed;
        &:hover {
          background: var(--bg-primary-light);
        }
      }
      &.primaryColor {
        svg {
          color: var(--primary-color);
        }
      }

      &.secondaryColor {
        color: #a1a3a4;
      }

      &:focus {
        outline: 0;
      }

      &[aria-expanded='true'],
      &:hover {
        background-color: var(--bg-secondary);
      }
      &Subscriber {
        display: inline-block;
        height: auto;
        &[aria-expanded='true'],
        &:hover {
          background-color: var(--ui_teal_light-surface-primary-hover);
        }
      }
      .actionElement > * {
        width: 40px;
      }
      // todo if this breaks anywhere, need to put classname for label and make changes in dashboard banner

      .label {
        @include multi-line-ellipsis(1);
        text-align: start;
        word-break: break-all;
        &NoWrap {
          white-space: nowrap;
        }
      }
    }

    .option {
      border-bottom: 1px solid rgba(206, 208, 212, 0.6);
      &.border-less {
        border: none;
      }
      &:last-of-type {
        border: none;
      }
      .subContainer {
        .optionsItem {
          border-radius: 12px;
        }
      }
      .item {
        border-radius: 12px;
      }
    }
  }

  .options {
    z-index: 3;
    &Top {
      top: 120%;
    }
    &Bottom {
      bottom: 120%;
    }

    &.left {
      right: -6px;
    }

    &.right {
      left: -6px;
    }
  }

  .subContainer {
    position: relative;

    .optionsItem {
      @include flex(center, space-between);
      padding: 11px 16px;
      @include f-inter-regular(1.5rem, 1.6rem);
      cursor: pointer;
      &[aria-expanded='true'],
      &:hover {
        background-color: var(--bg-secondary);
      }
      &Subscriber {
        padding: 7px 10px;
        @include f-inter-regular-2(1.4rem, 2.2rem);
        color: var(--text-primary-v2);
        &[aria-expanded='true'],
        &:hover {
          background-color: var(--ui_teal_light-surface-primary-hover);
        }
        &Icon {
          width: 12px;
          height: 12px;
          transform: rotate(270deg);
          color: var(--text-icon-on-lights-tertiary);
        }
      }
    }
  }

  .subOptions {
    top: 8px;
    &.left {
      right: 200px;
    }
    &.right {
      left: 75px;
    }
    &.subscriberLeft {
      @include f-inter-regular-2(1.4rem, 2.2rem);
      right: 160px;
      padding: 4px;
      top: -4px;
      min-width: 204px;
    }
  }
}
.enter,
.leave {
  transition: all 100ms ease-in-out;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.95);
}

.enterTo,
.leaveFrom {
  opacity: 1;
  transform: scale(1);
}
