@import 'styles/common.scss';

.TextArea {
  @include flex-column;
  &Element {
    @include fns-semi-bold(1.6rem, 2.4rem);
    width: 100%;
    color: var(--text-secondary);
    background: var(--bg-primary);
    border-radius: 10px;
    padding: 11px 15px 11px 11px;
    border: 1px solid var(--border-color);
    resize: none;

    &WithIcon {
      padding: 11px 33px 11px 11px;
      overflow: hidden;
    }

    &[disabled],
    &[readonly] {
      color: var(--bg-disabled);
    }
    &:focus {
      outline: none;
      border-color: var(--primary-color-light);
    }
    &::placeholder {
      @include f-inter-regular(1.6rem, 2.6rem);
      color: rgba(8, 12, 11, 0.44);
    }
    &:hover {
      border: 1.5px solid rgba(8, 12, 11, 0.3);
      box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);
    }
  }
  &Gap {
    gap: 4px;
  }
  &Label {
    @include flex(center, space-between);
    label {
      @include flex(center);
      @include fns-regular(1.4rem, 1.6rem);
      margin-bottom: 8px;
      color: var(--text-primary);
      .optional {
        @include f-inter-semi-bold(1.1rem, 1.3rem);
        color: rgba(8, 12, 11, 0.5);
        padding-left: 4px;
      }
      .infoToolTip {
        padding-left: 4px;
        line-height: 0;
      }
      &.HelperText {
        color: var(--complementary-secondary);
      }
    }
  }
  &LabelDate {
    label {
      @include f-inter-regular-2(1.4rem, 2rem);
      color: var(--text-primary-v2);
      margin-bottom: 0px;
    }
  }
  &IsInvalid {
    textarea {
      &,
      &:focus {
        background: #feecee;
        box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
        border: 1.5px solid #e5132e !important;
      }
    }
  }
  &Icon {
    position: absolute;
    right: 8px;
    top: 9px;

    &WithLabel {
      top: 33px;
    }
    &Selection {
      color: var(--text-icon-on-lights-secondary);
      width: 12px;
      height: 12px;
      position: absolute;
      right: 14px;
      top: 44px;
    }
  }
}
