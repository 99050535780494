@import 'styles/common.scss';

@mixin tick-icon($color) {
  background: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.78154 5.59994L7.96076 1.35544C8.15317 1.16003 8.46831 1.16003 8.66071 1.35544C8.84894 1.54661 8.84894 1.85345 8.66071 2.04462L3.78154 7L1.33929 4.5196C1.15106 4.32843 1.15106 4.02159 1.33929 3.83042C1.53169 3.63501 1.84683 3.63501 2.03924 3.83042L3.78154 5.59994Z' fill='#{$color}' stroke='#{$color}'/%3E%3C/svg%3E%0A")
    no-repeat;
}

.Checkbox {
  input {
    appearance: none;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 3px;
    border: 2px solid var(--border-color);
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    &:checked {
      @include tick-icon('%23ffffff');
      
      padding: 0;
      border: 0;
      background-color: var(--primary-color);
      background-position: left 5px top 6px;
    }
  }
}
